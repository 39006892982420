<template>
  <!-- Table Container Card -->
  <b-card no-body>
    <div class="m-2">
      <!-- Table Top -->
      <b-row class="justify-content-end">
        <!-- Entry -->
        <!-- <b-col
          cols="6"
          md="9"
          class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
        >
          <div class="d-flex align-items-center justify-content-end">
            <b-form-input
              v-model="searchQuery"
              class="d-inline-block mr-1"
              :placeholder="$t('globalActions.findCode')"
            />
          </div>
          <div class="d-flex align-items-center justify-content-end">
            <b-button variant="primary" @click="searchData(searchQuery)">
              Cari
            </b-button>
          </div>
        </b-col> -->

        <!-- Search -->
        <b-col
          cols="12"
          md="3"
          class="d-flex align-items-center justify-content-end mb-1 mb-md-0"
        >
          <b-button-group>
            <b-dropdown right variant="primary" no-caret>
              <template #button-content>
                <div class="d-flex align-items-center justify-content-between">
                  <span class="mr-2">Create</span>
                  <feather-icon size="16" icon="MoreVerticalIcon" />
                </div>
              </template>

              <b-dropdown-item
                :to="{ name: 'apps-transactions-cashbank-add-transfer' }"
              >
                <span>{{ $t("globalSingular.transfer") }}</span>
              </b-dropdown-item>

              <b-dropdown-group :header="$t('globalSingular.cash')">
                <b-dropdown-item
                  :to="{
                    name: 'apps-transactions-cashbank-add-cash-in',
                    params: { transactionTypeCode: 'CI' },
                  }"
                >
                  <span class="ml-2">{{ $t("globalSingular.income") }}</span>
                </b-dropdown-item>

                <b-dropdown-item
                  :to="{
                    name: 'apps-transactions-cashbank-add-cash-out',
                    params: { transactionTypeCode: 'CO' },
                  }"
                >
                  <span class="ml-2">{{ $t("globalSingular.expense") }}</span>
                </b-dropdown-item>
              </b-dropdown-group>

              <b-dropdown-group :header="$t('globalSingular.bank')">
                <b-dropdown-item
                  :to="{
                    name: 'apps-transactions-cashbank-add-bank-in',
                    params: { transactionTypeCode: 'BI' },
                  }"
                >
                  <span class="ml-2">{{ $t("globalSingular.income") }}</span>
                </b-dropdown-item>
                <b-dropdown-item
                  :to="{
                    name: 'apps-transactions-cashbank-add-bank-out',
                    params: { transactionTypeCode: 'BO' },
                  }"
                >
                  <span class="ml-2">{{ $t("globalSingular.expense") }}</span>
                </b-dropdown-item>
              </b-dropdown-group>

            </b-dropdown>
          </b-button-group>
        </b-col>
      </b-row>
    </div>

    <b-table
      id="refListTable"
      ref="refListTable"
      no-provider-paging
      no-provider-filtering
      :fields="tableColumns"
      :items="itemLists"
      responsive
      primary-key="id"
      show-empty
      :busy="isBusy"
      :empty-text="$t('globalSingular.noMatching')"
      :sort-desc.sync="isSortDirDesc"
      class="position-relative"
    >
      <template #table-busy>
        <div
          class="text-center text-primary my-2 d-flex flex-column justify-content-center"
        >
          <div class="text mb-1">
            <b-spinner class="align-middle" />
          </div>
          <strong>{{ $t("globalActions.fetchingDataAPI") }}</strong>
        </div>
      </template>

      <template #head()="{ field }">
        <table-search-column
          :field="field"
          :fields="tableColumns"
          :focused-field="focusedSearchField"
          @set-focused-field="(value) => focusedSearchField = value"
          @search-data="(params) => searchData(params)"
        />
      </template>

      <!-- Column: code -->
      <template #cell(codes)="{ item }">
        <b-link
          :to="{
            name: `${
              item.transaction_type_code === 'TF'
                ? 'apps-transactions-cashbank-detail-transfer'
                : 'apps-transactions-cashbank-detail'
            }`,
            params: {
              id: item.id,
              transactionTypeCode: item.transaction_type_code,
            },
          }"
          class="font-weight-bolder"
        >
          <span class="font-weight-bolder d-block text-nowrap">
            {{ item.code }}
          </span>
        </b-link>
      </template>

      <!-- Column: Account Type -->
      <template #cell(label)="{ item }">
        <!-- {{ data.value.type_name }} -->
        <span class="font-weight-bolder d-block text-nowrap">
          {{ item.contact ? item.contact.label : "Transfer" }}
        </span>
        <small class="font-italic">
          {{ item.remark }} {{ $t("globalSingular.via") }}
          <span class="font-weight-bolder text-primary">{{
            companyActive.plan_id > 2
              ? item.office
                ? item.office.label
                : item.account.account_name
              : item.account.account_name
          }}</span>
        </small>
      </template>

      <template #cell(cashbank)="{ item }">
        <div
          v-if="
            item.transaction_type_code === 'TF' && item.is_transfer_verified
          "
        >
          <span
            v-if="item.amount > 0"
            class="font-weight-bolder d-block text-nowrap"
          >
            {{ item.cashbank === null ? "-" : item.cashbank.label }}
          </span>
        </div>
        <div v-else>
          <span
            v-if="item.amount < 0 && item.transaction_type_code === 'TF'"
            class="font-weight-bolder d-block text-nowrap"
          >
            {{ item.cashbank === null ? "-" : item.cashbank.label }}
          </span>
          <span v-else class="font-weight-bolder d-block text-nowrap">
            {{ item.cashbank === null ? "-" : item.cashbank.label }}
          </span>
        </div>
      </template>

      <!-- Column: Account Type -->
      <template #cell(transaction_type)="{ item }">
        <!-- {{ data.value.type_name }} -->
        <div
          v-if="
            item.transaction_type_code === 'TF' && item.is_transfer_verified
          "
        >
          <!-- <div v-if="item.amount > 0 && item.transactionType.remark_eng === 'Cash & Bank Transfer' "> -->
          <span class="font-weight-bolder d-block text-nowrap">
            <div v-if="item.cashbank.is_bank">Bank Transfer In</div>
            <div v-else>Cash Transfer In</div>
          </span>
        </div>
        <div
          v-else-if="
            item.transaction_type_code === 'TF' &&
            (item.is_transfer_verified === false ||
              item.is_transfer_verified === null)
          "
        >
          <span class="font-weight-bolder d-block text-nowrap">
            <div v-if="item.cashbank.is_bank">Bank Transfer Out</div>
            <div v-else>Cash Transfer Out</div>
          </span>
        </div>
        <div v-else>
          <span class="font-weight-bolder d-block text-nowrap">
            {{ item.transactionType.remark_eng }}
          </span>
        </div>
      </template>

      <!-- Column: Amount -->
      <template #cell(amount)="{ item }">
        <span
          :class="[
            'font-weight-bolder',
            'd-block',
            'text-nowrap',
            item.amount > 0 ? 'text-success' : 'text-danger',
          ]"
        >
          <div v-if="item.amount > 0">
            <!-- {{ item.amount }} -->
            {{ formatCurrency(item.amount, false) }}
          </div>
          <div v-else>
            <!-- ( {{ item.amount * -1 }} ) -->
            ({{ formatCurrency(item.amount * -1, false) }})
          </div>
        </span>
      </template>

      <!-- Column: Tags -->
      <template #cell(tags)="{ item }">
        <div v-if="(item.tags || []).length > 0" class="d-flex">
          <b-badge
            v-for="(tag, index) in item.tags"
            :key="index"
            pill
            variant="light-primary"
            >{{ tag.name }}</b-badge
          >
        </div>
      </template>

      <!-- Column: audit -->
      <template #cell(audit)="{ item }">
        <span class="font-weight-bolder d-block text-nowrap">
          {{ item.updated_process }} By {{ item.getUser.user_name }}
        </span>
        <small class="font-italic">{{
          new Date(item.updated_at).toDateString()
        }}</small>
      </template>
    </b-table>

    <div class="mx-2 mb-2">
      <b-row>
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-start"
        >
          <span class="text-muted"
            >{{ $t("globalSingular.showing") }} {{ dataMeta.from }}
            {{ $t("globalSingular.to") }} {{ dataMeta.to }}
            {{ $t("globalSingular.of") }} {{ dataMeta.of }}
            {{ $t("globalSingular.entries") }}</span
          >
        </b-col>
        <!-- Pagination -->
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-end"
        >
          <b-pagination
            v-model="currentPage"
            :total-rows="totalList"
            per-page="15"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
            aria-controls="refListTable"
          >
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18" />
            </template>
            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18" />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </div>
  </b-card>
</template>


<script>
import {
  BCard,
  BButtonGroup,
  BDropdown,
  BDropdownGroup,
  BDropdownItem,
  BRow,
  BCol,
  BTable,
  BLink,
  BPagination,
  BSpinner,
  BBadge,
} from "bootstrap-vue"
import TableSearchColumn from "@/components/TableSearchColumn.vue"

import { formatCurrency } from "@/utils/formatter"
import useListTablePage from "@/comp-functions/useListTablePage"
import { ref } from '@vue/composition-api'

// import useHttp from '@/comp-functions/useHttp'
// to access i18n
import useLocalization from "@/comp-functions/utils/useLocalization"
import storestate from '@/store/index'

export default {
  components: {
    BCard,
    BButtonGroup,
    BDropdown,
    BDropdownGroup,
    BDropdownItem,
    BRow,
    BCol,
    BTable,
    BLink,
    BPagination,
    BSpinner,
    BBadge,
    TableSearchColumn
  },


  setup() {
    const { $t } = useLocalization()
    const isNational = storestate.state.user.isNational

    const {
      isBusy,
      itemLists,
      perPage,
      currentPage,
      totalList,
      dataMeta,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refListTable,
      statusFilter,
      focusedSearchField,
      fetchLists,
      loadPage,
      changePerPage,
      searchData
    } = useListTablePage({ url: "transaction/cashbank" })

    // Table Handlers
    const tableColumns = ref([
      {
        key: "codes",
        label: $t("globalSingular.codeTransactions"),
        thClass: 'px-1',
        tdClass: 'px-1',
        search: {
          type: 'text',
          value: '',
          key: 'code'
        }
      },
      {
        key: "date",
        label: $t("globalSingular.date"),
        thClass: 'px-1',
        tdClass: 'px-1',
        sortable: true,
        search: { type: 'date', value: '' }
      },
      {
        key: "office.label",
        label: $t("globalSingular.branch"),
        thClass: 'px-1',
        tdClass: 'px-1',
        search: {
          type: 'select',
          value: '',
          key: 'office_id',
          url: 'value/getBranch'
        }
      },
      {
        key: "cashbank",
        label: $t("globalSingular.cashBank"),
        thClass: 'px-1',
        tdClass: 'px-1',
        sortable: true,
        search: {
          type: 'select',
          value: '',
          key: 'cashbank_id',
          url: `value/mapping-cashbank-per-users?is_national=${isNational}`,
        }
      },
      {
        key: "label",
        label: $t("globalSingular.description"),
        thClass: 'px-1',
        tdClass: 'px-1',
        search: {
          type: 'text',
          value: '',
          key: 'remark'
        }
      },
      {
        key: "transaction_type",
        label: $t("globalSingular.transactionType"),
        thClass: 'px-1',
        tdClass: 'px-1',
        search: {
          type: 'select',
          value: '',
          key: 'transaction_type_code',
          url: `value/transaction-type`,
          server_side: false
        }
      },
      {
        key: "amount",
        label: $t("globalSingular.amount"),
        thClass: "text-right px-1",
        tdClass: "text-right px-1",
        search: { type: 'number', value: 0 }
      },
      {
        key: "tags",
        label: $t("globalSingular.tags"),
        thClass: 'px-1',
        tdClass: 'px-1',
        search: { type: 'text', value: '' }
      },
      {
        key: "audit",
        label: $t("globalSingular.audit"),
        thClass: 'px-1',
        tdClass: 'px-1',
        search: { type: 'text', value: '' }
      },
    ])

    return {
      formatCurrency,
      tableColumns,
      isBusy,
      itemLists,
      perPage,
      currentPage,
      totalList,
      dataMeta,
      searchData,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refListTable,
      statusFilter,
      fetchLists,
      changePerPage,
      loadPage,
      focusedSearchField,
    }
  },


  computed: {
    companyActive() {
      return this.$store.state.user.currentCompany
    },
  },
}
</script>
